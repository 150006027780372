.button {
  height: 10vh;
  min-height: 44px;
  max-height: 84px;

  width: 10vh;
  min-width: 44px;
  max-width: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #f5f5f5;
  border-radius: 50%;
  box-shadow: 0px 1.86667px 4.66667px rgba(0, 0, 0, 0.7);
}

.button > img {
  pointer-events: none;
}

.button:active {
  opacity: 0.7;
}
