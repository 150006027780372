h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: unset;
  margin-block-end: unset;
}

html,
body,
:root,
button,
input {
  all: unset;
}

html,
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  touch-action: pan-x pan-y;
  touch-action: double-tap-zoom;
}

button {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --gluon-grey: #1c1c1e;
  --blackish-grey: #5c5c61;
  --aluminum-silver: #8e8e93;
  --sparkling-metal: #c3c3c6;
  --accent: #e38f34;
  --red: red;
  --black: black;
}

:root {
  all: unset;
  background-color: var(--black);
  color: white;
  font-family: "Roboto", sans-serif;
  user-select: none;
}

h1 {
  font-size: 110px;
  font-style: normal;
  font-weight: 400;
  line-height: 129px;
  letter-spacing: -0.03em;
  text-align: center;
}

h2 {
  font-size: 42px;
  margin-bottom: 90px;
  font-style: normal;
  font-weight: 400;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--blackish-grey);
}

h3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #8e8e93;
}

p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-align: center;
}

input {
  background-color: #1c1c1e;
  height: 70px;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-align: center;
}
