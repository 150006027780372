.component > button {
  height: 100%;
}

.component > button:first-child {
  transform: rotate(180deg);
}

.controlButtonsContainer {
  pointer-events: none;
  position: absolute;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.controlButtonsContainer > button {
  pointer-events: auto;
}
